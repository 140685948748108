// Vous pouvez écrire du markdown
export default `


- Lundi 20/11 à 13h : présentation du modèle de language Albert (DINUM)

# @bientôt

---

![bon appétit](https://media.giphy.com/media/2s7lb48XP0yje/giphy.gif)


`
